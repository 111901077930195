import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import InstitutionDetails from '../HEInstitutionDetails';
import Icon from '../../components/Icon';

const InstitutionPage = ({ collegeByScid, location, match }) => {
  const linkBackToThis = location ? pick(location, 'pathname', 'search') : undefined;
  const linkBackToParent = get(location, 'state.linkBack');
  let institution;
  if (collegeByScid) {
    institution = {};
    institution.id = get(collegeByScid, 'heAccount.id');
    institution.name = collegeByScid.name;
    institution.primaryUser = get(collegeByScid, 'heAccount.primaryUser');
    institution.address = collegeByScid.address;
    institution.scid = match.params.scid;
  }
  return (
    <div>
      {
        linkBackToParent &&
        (
          <Link to={linkBackToParent}><Icon name="angle left" />
            <FormattedMessage id="institution.backtosearch" defaultMessage="Back to search" />
          </Link>
        )
      }
      {
        linkBackToThis ?
          <InstitutionDetails institution={institution} linkBack={linkBackToThis} parentLinkBack={linkBackToParent} /> :
          <InstitutionDetails institution={institution} />
      }
    </div>
  );
};

InstitutionPage.propTypes = {
  collegeByScid: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      linkBack: PropTypes.object,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      scid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InstitutionPage;
