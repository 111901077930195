import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Footer from '../../components/Footer';
import Message from '../../components/Message';
import SiteHeader from '../../components/SiteHeader';
import './styles.css';

// main layout
class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = { hasError: false };

  componentDidMount() {
    this.routerListener = this.props.history.listen(() => this.setState({ hasError: false }));
  }

  componentWillUnmount() {
    this.routerListener();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    if (window.Sentry) {
      window.Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        window.Sentry.captureException(error);
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    return (
      <div styleName="wrapper">
        <div styleName="main">
          <header styleName="header" role="banner">
            <SiteHeader />
          </header>
          <main styleName="content">
            {hasError ? <Message error header="Oops, something went wrong" /> : children }
          </main>
          <footer styleName="footer">
            <Footer />
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
