import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import get from 'lodash/get';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import Container from '../../components/Container';
import Segment from '../../components/Segment';
import RequestUserLink from '../../components/RequestUserLink';

const messages = defineMessages({
  updateLink: {
    id: 'he-userRequest.link.ContactUs',
    defaultMessage: 'please complete this form.',
  },
  requestLink: {
    id: 'he-userRequest.link.RequestUser',
    defaultMessage: 'please complete this form.',
  },
  primaryDoesNotExistsLinkInstructionsMessage: {
    id: 'he-userRequest.link.RequestInstructions',
    defaultMessage: 'Review the details above to confirm this is your institution. To become the primary user for your institution, ',
  },
  primaryDoesExistsLinkInstructionsMessage: {
    id: 'he-userRequest.link.ContactUsInstructions',
    defaultMessage: 'To request an account for your institution, ',
  },
});

const Institution = ({ institution, linkBack, parentLinkBack }) => {
  if (!institution) {
    return (
      <p>
        <FormattedMessage id="institution.notFound" defaultMessage="No Institution Found" />
      </p>
    );
  } else {
    const address = get(institution, 'address', {});
    return (
      <Container text>
        <Header as="h1">
          {institution.name}
        </Header>
        <Segment>
          <Grid columns="equal" stackable>
            <Grid.Row centered>
              <Grid.Column as="section">
                <div className="vcard">
                  <div className="adr">
                    <div className="street-address">{address.street}</div>
                    <div className="extended-address">{address.street2}</div>
                    <span className="locality">{address.city}</span>,&nbsp;
                    <span className="region">{address.state}</span>&nbsp;
                    <span className="postal-code">{address.postalCode}</span>
                  </div>
                </div>
                <p />
                {institution.primaryUser ? (
                  <div className="primary-user">
                    <div className="primary-exists">
                      <FormattedMessage id="institution.primaryUser.found" defaultMessage="Primary User -" />&nbsp;
                      {institution.primaryUser.firstName} {institution.primaryUser.lastName}
                    </div>
                    <p />
                    <RequestUserLink
                      linkBack={linkBack}
                      parentLinkBack={parentLinkBack}
                      id={institution.scid}
                      linkInstructionsMessage={messages.primaryDoesExistsLinkInstructionsMessage}
                      linkMessage={messages.updateLink}
                      userType="he"
                    />
                    <p />
                  </div>
                ): (
                  <div>
                    <p>
                      <FormattedMessage id="institution.primaryUser.notFound" defaultMessage="No Primary User Exists" />
                    </p>
                    <RequestUserLink
                      linkBack={linkBack}
                      parentLinkBack={parentLinkBack}
                      id={institution.scid}
                      linkInstructionsMessage={messages.primaryDoesNotExistsLinkInstructionsMessage}
                      linkMessage={messages.requestLink}
                      userType="he"
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
};

Institution.propTypes = {
  institution: PropTypes.shape({
    id: PropTypes.string,
    scid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string,
      street2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
    }).isRequired,
    primaryUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
  linkBack: PropTypes.object,
  parentLinkBack: PropTypes.object,
};

export default Institution;
