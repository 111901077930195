import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { loadMorePaginationProp, SimpleLoadingComponent } from '../../utils/apollo';

import Institutions from './institutions-table';

const query = gql`
  query Viewer($filter: String!, $first: Int, $after: String) {
    viewer {
      query(filter: $filter, first: $first, after: $after) {
        edges {
          node {
            id
            name
            field_he_institution_id
            field_postalcode
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ filter }) => ({
    variables: {
      first: 5,
      filter,
    },
  }),
  props: loadMorePaginationProp(query),
});

export default SimpleLoadingComponent(
  graphqlData,
)(Institutions);
