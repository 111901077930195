/*
 *
 * searchTerm actions
 *
 */

import { CHANGE_SEARCHTERM } from './constants';

export function changeSearchTerm(term) {
  return {
    type: CHANGE_SEARCHTERM,
    payload: {
      searchTerm: term,
    },
  };
}

export function searchTermChanged(searchTerm) {
  return (dispatch) => {
    dispatch(changeSearchTerm(searchTerm));
  };
}

export default changeSearchTerm;
