import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import pick from 'lodash/pick';

import Search from '../../components/Search';

import SearchResultRenderer from './search-result-renderer';
import './styles.css';

export const query = gql`
  query searchHE($maxSize: Int!, $filter: String!) {
    viewer {
      search(filter: $filter, maxSize: $maxSize) {
        ... on HigherEd {
          name
          field_he_institution_id
          field_postalcode
        }
      }
    }
  }
`;

function generateTitle(name, postalCode) {
  if (postalCode) {
    return `${name} - ${postalCode.substring(0, 5)}`;
  } else {
    return `${name}`;
  }
}

class SearchBar extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    client: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    defaultValue: PropTypes.string,
    onFilterChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onFilterChange = props.onFilterChange;
    this.state = { isLoading: false };
  }

  resetComponent() {
    this.setState({ isLoading: false, results: [] });
  }

  handleOnChange = (e, { result }) => {
    const linkBack = pick(this.props.location, 'pathname', 'search');
    this.props.history.push({ pathname: `/he/institution/${result.key}/`, state: { linkBack } });
  };

  handleSearchChange = (e, { value }) => {
    if (value.length < 1) {
      this.resetComponent();
    } else {
      this.fetchResults(value);
    }
    this.onFilterChange(value);
  };

  async fetchResults(value) {
    const { client } = this.props;
    this.setState({ isLoading: true });
    const { data } = await client.query({
      query,
      variables: {
        maxSize: 5,
        filter: value,
      },
    });
    const results = map(get(data, 'viewer.search'), (each) => ({
      role: 'option',
      renderer: SearchResultRenderer,
      key: each.field_he_institution_id,
      title: generateTitle(each.name, each.field_postalcode),
    }));
    this.setState({ isLoading: false, results });
  }

  render() {
    const { results, isLoading } = this.state;
    const { defaultValue } = this.props;
    return (
      <div styleName="searchBarContainer">
        <Search
          id="global-search-box"
          className="labeled"
          fluid
          defaultValue={defaultValue}
          placeholder="Search Institutions..."
          input={{
            'name': 'search',
            'role': 'textfield',
            'aria-autocomplete': 'inline',
            'aria-label': 'Search',
          }}
          results={results}
          onSearchChange={this.handleSearchChange}
          onResultSelect={this.handleOnChange}
          loading={isLoading}
          showNoResults={!isLoading}
          icon="university"
        />
      </div>
    );
  }
}

export const SearchBarComponent = withRouter(SearchBar);

export default withApollo(SearchBarComponent);
