import React from 'react';

import { Redirect, Switch, Route } from 'react-router-dom';

import HEInstitutionPage from './containers/HEInstitutionPage';
import HSInstitutionPage from './containers/HSInstitutionPage';
import RegistrationPage from './containers/RegistrationPage';
import RequestHEUserPage from './containers/RequestHEUserPage';
import HSRequestUserPage from './containers/HSRequestUserPage';
import RequestInstitutionPage from './containers/RequestInstitutionPage';
import HSRequestInstitutionPage from './containers/HSRequestInstitutionPage';

export default (
  <Switch>
    <Route
      exact
      path="/"
      component={(props, state, params) => <RegistrationPage userType={null} {...props} />}
    />
    <Route
      exact
      path="/he"
      component={(props, state, params) => <RegistrationPage userType="he" {...props} />}
    />
    <Route
      exact
      path="/hs"
      component={(props, state, params) => <RegistrationPage userType="hs" {...props} />}
    />
    <Route
      exact
      path="/he/institution/:scid/"
      component={HEInstitutionPage}
    />
    <Route
      exact
      path="/he/institution/:scid/requestuser"
      component={RequestHEUserPage}
    />
    <Route
      exact
      path="/he/requestinstitution"
      component={RequestInstitutionPage}
    />
    <Route
      exact
      path="/hs/requestinstitution"
      component={HSRequestInstitutionPage}
    />
    <Route
      exact
      path="/hs/institution/:id/"
      component={HSInstitutionPage}
    />
    <Route
      exact
      path="/hs/institution/:id/requestuser"
      component={HSRequestUserPage}
    />
    <Redirect from="*" to="/" />
  </Switch>
);
