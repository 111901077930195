/**
 * Login Button actions
 */

import { SET_LOGIN_VISIBILITY } from './constants';

export function setLoginVisibility(visibility) {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN_VISIBILITY,
      payload: {
        visible: visibility,
      },
    });
  };
}

export default setLoginVisibility;
