import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash/get';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HSRequestUserForm from '../../components/RequestUserForm';
import Icon from '../../components/Icon';
import Container from '../../components/Container';
import Header from '../../components/Header';
import HSRequestInstitutionMutation from './request-institution-mutation';

export class HSRequestInstitution extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
    mutateHSRequestInstitution: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    requesting: false,
    userExists: false,
    isError: false,
    isDone: false,
  };

  getLinkBack() {
    return get(this.props, 'location.state.linkBack', null);
  }

  navigateToCaller() {
    const path = this.getLinkBack() || '/';
    this.props.history.push(path);
  }

  _handleCancel = () => {
    this.navigateToCaller();
  };

  /* istanbul ignore next */
  _handleDone = () => {
    const path = process.env.HS_LOGIN_URL;
    window.location = path;
  };

  _handleSave = ({ firstName, lastName, email, jobTitle, institutionName, institutionWebsite,
    isCitizenOfEU, hasAgreedToTermsOfUse, hasAgreedToPrivacyPolicy }) => {
    const { mutateHSRequestInstitution } = this.props;
    const onFailure = (error) => {
      const newState = { requesting: false, isError: true };
      if (error && error.message && error.message.includes('already exists.')) {
        newState.userExists = true;
      }
      this.setState(newState);
    };

    const onSuccess = () => {
      this.setState({ isDone: true });
    };

    this.setState({ requesting: true, isError: false, userExists: false });

    mutateHSRequestInstitution({
      variables: {
        input: {
          requestHSInstitution: {
            firstName,
            lastName,
            email,
            jobTitle,
            institutionName,
            institutionWebsite,
            isCitizenOfEU,
            hasAgreedToTermsOfUse,
            hasAgreedToPrivacyPolicy,
          },
        },
      },
    }).then(onSuccess).catch(onFailure);
  };

  render() {
    const { requesting, isError, isDone, userExists } = this.state;
    const linkBack = this.getLinkBack();
    return (
      <div>
        {
          linkBack &&
        (<Link to={linkBack}><Icon name="angle left" />Back</Link>)
        }
        <Container text className="requestPrimaryUserPanel">
          <Header as="h1">
            <FormattedMessage
              id="hs-userRequest.newInstitutionHeader"
              description="Header for requesting to be the primary user of an new institution"
              defaultMessage="Request New Institution"
            />
          </Header>
          <HSRequestUserForm
            requesting={requesting}
            onSave={this._handleSave}
            onCancel={this._handleCancel}
            isError={isError}
            isDone={isDone}
            userExists={userExists}
            newInstitution
            showJobTitle
            onModalClose={this._handleDone}
          />
        </Container>
      </div>
    );
  }
}

const hsRequestInstitutionMutation = graphql(HSRequestInstitutionMutation, {
  name: 'mutateHSRequestInstitution',
});

export const HSRequestInstitutionPageComponent = withRouter(HSRequestInstitution);
export default hsRequestInstitutionMutation(HSRequestInstitutionPageComponent);
