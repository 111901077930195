import React from 'react';
import './styles.css';
import Grid from '../Grid';

const Footer = () => (
  <Grid verticalAlign="middle" styleName="container">
    <Grid.Row textAlign="center" only="computer">
      <Grid.Column width={5} textAlign="left" />
      <Grid.Column width={5}>&copy; {new Date().getFullYear()} PowerSchool Group LLC. All Rights Reserved.</Grid.Column>
      <Grid.Column styleName="footer-links" width={6} textAlign="right">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://static.intersect.hobsons.com/terms.html"
          aria-label="Terms of Use"
        >
          Terms of Use
        </a>
        <a
          href="https://www.powerschool.com/privacy/"
          styleName="privacy-link"
          aria-label="Privacy Statement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row only="tablet mobile">
      <Grid verticalAlign="middle" styleName="container">
        <Grid.Row styleName="mobile-footer">
          <Grid.Column width={16} />
        </Grid.Row>
        <Grid.Row styleName="mobile-footer">
          <Grid.Column width={11}>&copy; {new Date().getFullYear()} PowerSchool Group LLC. All Rights Reserved.</Grid.Column>
        </Grid.Row>
        <Grid.Row styleName="mobile-footer">
          <Grid.Column styleName="footer-links" width={15}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://static.intersect.hobsons.com/terms.html"
              aria-label="Terms of Use"
            >
              Terms of Use
            </a>
            <a
              href="https://www.powerschool.com/privacy/"
              styleName="privacy-link"
              aria-label="Privacy Statement"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Statement
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Row>
  </Grid>
);

export default Footer;
