import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HEInstitutionsTable from '../HEInstitutionsTable';
import HSInstitutionsTable from '../HSInstitutionsTable';
import HESearchBar from '../HESearchBar';
import HSSearchBar from '../HSSearchBar';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import Form from '../../components/Form';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Segment from '../../components/Segment';

import { searchTermChanged } from './actions';

import './styles.css';

class RegistrationPage extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    searchTerm: PropTypes.string,
    searchTermChanged: PropTypes.func,
    userType: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchFilter: this.props.searchTerm,
      showUserTypeSelector: true,
    };
  }

  handleUserTypeChange(userType) {
    this.props.history.replace({
      pathname: `/${userType}`,
    });
  }

  handleFilterChange = (value) => {
    this.setState({ searchFilter: value });
  };

  search = (e) => {
    const { searchFilter } = this.state;
    e.preventDefault();
    if (searchFilter) {
      this.props.searchTermChanged(searchFilter);
    }
  };

  /* eslint-disable max-len */
  render() {
    const { searchTerm, userType } = this.props;
    const { searchFilter, showUserTypeSelector } = this.state;
    return (
      <Container text>
        <Header as="h1">
          <FormattedMessage
            id="he-registration.newUserText"
            description="Message for new users"
            defaultMessage="New User? Find Your Institution"
          />
        </Header>
        <Segment>
          {showUserTypeSelector && (
          <Button.Group fluid styleName="user-type-selector">
            <Button
              onClick={() => this.handleUserTypeChange('he')}
              styleName={userType === 'he' ? 'user-type-selector-active' : 'user-type-selector-he'}
            >
              <FormattedMessage id="registration.higher-ed-staff" defaultMessage="Higher Education Staff Member" />
            </Button>
            <Button
              onClick={() => this.handleUserTypeChange('hs')}
              styleName={userType === 'hs' ? 'user-type-selector-active' : 'user-type-selector-hs'}
            >
              <FormattedMessage id="registration.high-school-staff" defaultMessage="High School Staff Member" />
            </Button>
          </Button.Group>
          )}
          {userType && (
          <Grid as={Form} onSubmit={this.search}>
            <Grid.Row>
              <Grid.Column computer={16} mobile={16} tablet={16}>
                {userType === 'he' && (
                <div>
                  <p>
                    <FormattedMessage
                      id="he-registration.searchInstitutionFirstParagraph"
                      description="Message for searching institutions"
                      defaultMessage="Intersect by PowerSchool brings together higher education admissions staff with high school counselors."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="he-registration.searchInstitutionSecondParagraph"
                      description="Message for searching institutions"
                      defaultMessage="Within the PowerSchool Counselor Community you can update your college profile for Naviance, develop a dynamic network of connections, search and explore high school profiles, and collaborate with the broader counseling community. You can request an account by searching for your institution's name or postal code below."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="he-registration.searchInstitutionTextDisclaimer"
                      description="Message for searching institutions"
                      defaultMessage="*Please note there can be multiple free accounts per higher education institution but only one of these accounts can be the primary user. The primary user account should be the main point of contact for your admissions office."
                    />
                  </p>
                </div>
                )}
                {userType === 'hs' && (
                <div>
                  <p>
                    <FormattedMessage
                      id="hs-registration.searchInstitutionFirstParagraph"
                      description="Message for searching institutions"
                      defaultMessage="Intersect by PowerSchool brings together higher education admissions staff with high school counselors."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="hs-registration.searchInstitutionSecondParagraph"
                      description="Message for searching institutions"
                      defaultMessage="Within the PowerSchool Counselor Community you can update your high school's profile, develop a dynamic network of connections, search and explore higher education institution profiles, and collaborate with the broader counseling community. You can request an account by searching for your institution's name or postal code below."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="hs-registration.searchInstitutionTextDisclaimer"
                      description="Message for searching institutions"
                      defaultMessage="*Please note there can be multiple free accounts per high school but only one of these accounts can be the primary user. The primary user account should be the main point of contact for your guidance office."
                    />
                  </p>
                </div>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={13} mobile={10} tablet={13}>
                {userType === 'he' && <HESearchBar defaultValue={searchTerm} onFilterChange={this.handleFilterChange} />}
                {userType === 'hs' && <HSSearchBar defaultValue={searchTerm} onFilterChange={this.handleFilterChange} />}
              </Grid.Column>
              <Grid.Column computer={3} mobile={6} tablet={3}>
                <Button type="submit" data-cy="search-button" primary disabled={!searchFilter}>
                  {userType === 'he' && (
                  <FormattedMessage
                    id="he-registration.searchButtonText"
                    description="Button to search for higher ed institutions"
                    defaultMessage="Search"
                  />
                  )}
                  {userType === 'hs' && (
                  <FormattedMessage
                    id="hs-registration.searchButtonText"
                    description="Button to search for high school institutions"
                    defaultMessage="Search"
                  />
                  )}
                </Button>
              </Grid.Column>
            </Grid.Row>
            { searchTerm ? (
              <Grid.Row>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  {userType === 'he' && searchTerm && <HEInstitutionsTable filter={searchTerm} />}
                  {userType === 'hs' && searchTerm && <HSInstitutionsTable filter={searchTerm} />}
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
          )}
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ searchTerm: get(state, 'searchTerm.searchTerm') });

export default connect(mapStateToProps, { searchTermChanged })(withRouter(RegistrationPage));
