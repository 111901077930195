import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash/get';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { purgeStoredState } from 'redux-persist';
import persistConfig from '../../persistConfig';
import RequestUserForm from '../../components/RequestUserForm';
import Icon from '../../components/Icon';
import Container from '../../components/Container';
import Header from '../../components/Header';
import { SimpleLoadingComponent } from '../../utils/apollo';
import RequestPrimaryUserMutation from './request-primaryuser-mutation';

class RequestHEUserPage extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
        parentLinkBack: PropTypes.object,
      }),
    }),
    collegeByScid: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        scid: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    mutateRequestPrimaryUser: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    requesting: false,
    userExists: false,
    isError: false,
    isDone: false,
  };

  getLinkBack() {
    return get(this.props, 'location.state.linkBack', null);
  }

  getParentLinkBack() {
    return get(this.props, 'location.state.parentLinkBack', null);
  }

  navigateToCaller() {
    const path = this.getLinkBack() || { pathname: `/he/institution/${this.props.match.params.scid}/` };
    this.props.history.push({ pathname: path.pathname, state: { linkBack: this.getParentLinkBack() } });
  }

  _handleCancel = () => {
    this.navigateToCaller();
  };

  /* istanbul ignore next */
  _handleDone = async () => {
    const path = process.env.HE_LOGIN_URL;
    await purgeStoredState(persistConfig);
    this.props.history.replace('/');
    window.location = path;
  };

  _handleSave = ({ firstName, lastName, email, reason, jobTitle, authorizedToPostPublicInformation,
    schedulesVisits, isCitizenOfEU, hasAgreedToTermsOfUse, hasAgreedToPrivacyPolicy }) => {
    const { mutateRequestPrimaryUser, match, collegeByScid } = this.props;
    const onFailure = (error) => {
      const newState = { requesting: false, isError: true };
      if (error && error.message && error.message.includes('already exists.')) {
        newState.userExists = true;
      }
      this.setState(newState);
    };

    const onSuccess = () => {
      this.setState({ requesting: false, isDone: true });
    };

    this.setState({ requesting: true, isError: false, userExists: false });
    mutateRequestPrimaryUser({
      variables: {
        input: {
          requestPrimaryUser: {
            firstName,
            lastName,
            email,
            reason,
            institutionName: collegeByScid.name,
            institutionSCID: match.params.scid,
            jobTitle,
            authorizedToPostPublicInformation,
            schedulesVisits,
            isCitizenOfEU,
            hasAgreedToTermsOfUse,
            hasAgreedToPrivacyPolicy,
          },
        },
      },
    }).then(onSuccess).catch(onFailure);
  };

  render() {
    const { isError, isDone, userExists, requesting } = this.state;
    const { collegeByScid } = this.props;
    const linkBack = this.getLinkBack();
    const parentLinkBack = this.getParentLinkBack();
    if (!collegeByScid) {
      return (
        <p data-cy="no-institution-found">
          <FormattedMessage id="institution.notFound" defaultMessage="No Institution Found" />
        </p>
      );
    } else {
      return (
        <div>
          {
            linkBack &&
            (<Link to={{ pathname: linkBack.pathname, state: { linkBack: parentLinkBack } }}><Icon name="angle left" />Back</Link>)
          }
          <Container text className="requestHEUserPanel">
            <Header as="h1">
              <FormattedMessage
                id="he-userRequest.header"
                description="Header for requesting to be the primary user of an existing institution"
                defaultMessage="Request User Account"
              />
            </Header>
            <RequestUserForm
              requesting={requesting}
              onSave={this._handleSave}
              onCancel={this._handleCancel}
              isError={isError}
              isDone={isDone}
              userExists={userExists}
              institutionName={collegeByScid.name}
              onModalClose={this._handleDone}
              showJobTitle
              showAuthorizedToPost
              showSchedulesVisits
            />
          </Container>
        </div>
      );
    }
  }
}

const requestPrimaryUserMutation = graphql(RequestPrimaryUserMutation, {
  name: 'mutateRequestPrimaryUser',
});

const query = gql`
  query CollegeByScid($scid: String!) {
    collegeByScid(id: $scid) {
      ... on CollegeCore {
        id
        name
        heAccount {
          primaryUser {
            name
          }
        }
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match }) => ({
    variables: {
      scid: match.params.scid,
    },
  }),
});

export const RequestHEUserPageComponent = withRouter(RequestHEUserPage);

export default withRouter(SimpleLoadingComponent(graphqlData, requestPrimaryUserMutation)(RequestHEUserPageComponent));
