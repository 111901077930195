/**
 * Login Button reducer
 */

import { SET_LOGIN_VISIBILITY } from './constants';
import { createReducer } from '../../utils';

const initialState = {
  visible: null,
};

export default createReducer(initialState, {
  [SET_LOGIN_VISIBILITY]: (state, payload) => ({
    ...state, ...payload,
  }),
});
