import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'isomorphic-fetch';
import 'focus-visible/dist/focus-visible';

import './theme';
import configureRedux from './configureRedux';
import history from './history';
import setupGA from './configureGA';

import getApolloClient from './apollo-client';
import App from './containers/App';
import routes from './routes';

import { translationMessages } from './i18n';
import LanguageProvider from './providers/LanguageProvider';

const appDom = document.getElementById('app');
const HotApp = hot(App);

console.log(`Starting HE Registration App @ ${process.env.VERSION}, built ${process.env.BUILDTIME} from ${process.env.BUILDBRANCH}`); // eslint-disable-line no-console

const renderUI = (store) => {
  if (process.env.A11Y) {
    require('./a11y').default(appDom); // eslint-disable-line global-require
  }
  const client = getApolloClient();

  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={client}>
        <LanguageProvider messages={translationMessages}>
          <Router history={history}>
            <HotApp>
              {routes}
            </HotApp>
          </Router>
        </LanguageProvider>
      </ApolloProvider>
    </Provider>,
    appDom,
  );
};

setupGA(history);
configureRedux({}, renderUI);
