import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';

export const appLocales = [
  'en',
  'es',
];

export const translationMessages = {
  en: enTranslationMessages,
  es: esTranslationMessages,
};
