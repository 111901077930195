import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import './styles.css';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import RequestUserLink from '../../components/RequestUserLink';

const messages = defineMessages({
  requestLink: {
    id: 'hs-userRequest.link.RequestInstitution',
    defaultMessage: 'Request new institution',
  },
});

class InstitutionsTable extends React.PureComponent {
  static propTypes = {
    viewer: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  };

  state = {
    loading: false,
  };

  loadMore = () => {
    this.setState({ loading: true });
    this.props.loadMore('viewer.queryHighSchools').then(() => this.setState({ loading: false }));
  };

  render() {
    const { viewer, location } = this.props;
    const linkBack = pick(location, 'pathname', 'search');

    if (!get(viewer, 'queryHighSchools.edges.length')) {
      return (
        <div data-cy="no-institutions-found">
          <p>
            <FormattedMessage
              id="hs-registration.noInstitutionsText"
              description="Message that there are no institutions found"
              defaultMessage="No Institutions Found"
            />
          </p>
          <div>
            <RequestUserLink
              linkBack={linkBack}
              linkMessage={messages.requestLink}
              userType="hs"
            />
          </div>
        </div>
      );
    } else {
      const rows = viewer.queryHighSchools.edges.map((institution) => institution.node);
      return (
        <Table id="institution-list">
          <caption styleName="institutionTable">List of Institutions</caption>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage
                  id="hs-registration.institutionName"
                  defaultMessage="Institution Name"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="hs-registration.postalCode"
                  defaultMessage="Postal Code"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row) => (
              <Table.Row key={row.id}>
                <Table.Cell>
                  <Link aria-label={row.name} to={{ pathname: `/hs/institution/${row.id}/`, state: { linkBack } }}>
                    {row.name}
                  </Link>
                </Table.Cell>
                <Table.Cell>{row.address.field_hs_eln_zip}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {get(viewer, 'queryHighSchools.pageInfo.hasNextPage') ? (
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan="2">
                  <Loader inline active={this.state.loading} styleName="loader" />
                  <Button size="mini" onClick={this.loadMore}>
                    <FormattedMessage
                      id="hs-registration.loadMore"
                      description="Button to trigger loading more institutions"
                      defaultMessage="More Institutions"
                    />
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          ): null}
        </Table>
      );
    }
  }
}

export default withRouter(InstitutionsTable);
