/* eslint-disable global-require */
import 'core-js';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

require('@formatjs/intl-getcanonicallocales/polyfill');
require('@formatjs/intl-locale/polyfill');

/* istanbul ignore else */
if (shouldPolyfillPluralRules()) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-pluralrules/locale-data/es'); // Add locale data for es
}

/* istanbul ignore else */
if (shouldPolyfillRelativeTimeFormat()) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-relativetimeformat/locale-data/es'); // Add locale data for es
}
