import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const TermsPrivacyLink = ({ title, link }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={link}
    styleName="link"
  > {title}
  </a>
);

TermsPrivacyLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default TermsPrivacyLink;
