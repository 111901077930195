import get from 'lodash/get';
import clone from 'lodash/clone';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { compose, flattenProp } from 'react-recompose';
import GraphQLLoader from '../components/GraphQLLoader';
import GraphQLError from '../components/GraphQLError';

/* istanbul ignore next */
function trimClone(definition, parts) {
  if (parts.length) {
    definition.selectionSet = clone(definition.selectionSet);
    definition.selectionSet.selections = filter(definition.selectionSet.selections, {
      name: { value: parts[0] },
    }).map((selection) => clone(selection));
    trimClone(definition.selectionSet.selections[0], parts.slice(1));
  }
}

/* istanbul ignore next */
function buildSubQuery(query, key) {
  const parts = key.split('.');
  const subQuery = clone(query);
  subQuery.definitions = subQuery.definitions.map((definition) => clone(definition));
  trimClone(subQuery.definitions[0], parts);
  return subQuery;
}

/* istanbul ignore next */
function traverseQuery(definition, parts) {
  if (parts.length) {
    const selection = find(definition.selectionSet.selections, {
      name: { value: parts[0] },
    });
    return traverseQuery(selection, parts.slice(1));
  }
  return definition;
}

/* istanbul ignore next */
function getInputName(query, key, name) {
  const parts = key.split('.');
  const selection = traverseQuery(get(query, 'definitions[0]'), parts);
  const argument = find(selection.arguments, { name: { value: name } });
  if (!argument) {
    throw new Error(`Unable to find 'after' argument at ${key}`);
  }
  if (argument.value.kind === 'Variable') {
    return argument.value.name.value;
  } else {
    return argument.value;
  }
}

/* istanbul ignore next */
export const loadMorePaginationProp = (query, { optimized } = {}) => ({ data }) => {
  const { loading, variables, error, fetchMore } = data;
  return {
    loading,
    error,
    data,
    loadMore: (collectionPath, extraVariables) => {
      const cursor = get(data, `${collectionPath}.pageInfo.endCursor`);
      if (!cursor) {
        throw new Error(
          `Unable to find cursor information at ${collectionPath}.pageInfo.endCursor`,
        );
      }
      return fetchMore({
        query: optimized ? buildSubQuery(query, collectionPath) : query,
        variables: { ...variables, ...extraVariables, [getInputName(query, collectionPath, 'after')]: cursor },
      });
    },
  };
};

export const SimpleLoadingComponent = (graphqlData, ...additional) =>
  compose(graphqlData, GraphQLLoader, GraphQLError, flattenProp('data'), ...additional);
export const SimpleGraphQLComponent = (graphqlData, ...additional) =>
  compose(graphqlData, GraphQLError, flattenProp('data'), ...additional);
