import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';

import { SimpleLoadingComponent } from '../../utils/apollo';
import InstitutionPage from './institution-page';

const query = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on HighSchool {
        id
        name
        hsAccount {
          primaryUser {
            firstName
            lastName
            name
          }
        }
        address {
          field_hs_elna_address_line1
          field_hs_elna_state_latest
          field_hs_elna_city
          field_hs_eln_zip
        }
        isNavianceSchool
      }
    }
  }
`;

const graphqlData = graphql(query, {
  options: ({ match }) => ({
    errorPolicy: 'all',
    variables: {
      id: match.params.id,
    },
  }),
});

export default withRouter(SimpleLoadingComponent(
  graphqlData,
)(InstitutionPage));
