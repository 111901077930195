import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const RequestUserLink = ({ linkBack, parentLinkBack, id, linkMessage, linkInstructionsMessage, userType }) => {
  const path = id ? `/${userType}/institution/${id}/requestuser` :`/${userType}/requestinstitution`;
  return (
    <div>
      {linkInstructionsMessage && (
        <FormattedMessage {...linkInstructionsMessage} />
      )}
      &nbsp;
      <Link
        data-cy="complete-form"
        to={{ pathname: path, state: { linkBack, parentLinkBack } }}
      >
        <FormattedMessage {...linkMessage} />
      </Link>
    </div>
  );
};

RequestUserLink.propTypes = {
  linkBack: PropTypes.object,
  parentLinkBack: PropTypes.object,
  id: PropTypes.string,
  linkMessage: PropTypes.object.isRequired,
  linkInstructionsMessage: PropTypes.object,
  userType: PropTypes.string.isRequired,
};

export default RequestUserLink;
