import React from 'react';
import PropTypes from 'prop-types';

const SearchResultRenderer = ({ title, id }) => (
  <div key="content" id={`search-box-item-${id}`} className="content">
    {title && <div className="title">{title}</div>}
  </div>
);
SearchResultRenderer.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  postalCode: PropTypes.string,
};

export default SearchResultRenderer;
