/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux';

import languageProviderReducer from './providers/LanguageProvider/reducer';
import searchTermProviderReducer from './containers/RegistrationPage/reducer';
import loginButtonReducer from './components/SiteHeader/reducer';

export default combineReducers({
  language: languageProviderReducer,
  searchTerm: searchTermProviderReducer,
  loginButton: loginButtonReducer,
});
