import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import persistConfig from './persistConfig';

export default function configureStore(initialState = {}, callback) {
  // Create the store with two middlewares
  // 1. thunk
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    thunk,
  ];

  /* istanbul ignore if */
  if (process.env.__DEVCLIENT__) {
    middlewares.push(createLogger());
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  persistStore(store, null, () => callback(store));
}
