/*
 *
 * searchTerm reducer
 *
 */

import { CHANGE_SEARCHTERM } from './constants';
import { createReducer } from '../../utils';

const initialState = {
  searchTerm: '',
};

export default createReducer(initialState, {
  [CHANGE_SEARCHTERM]: (state, payload) => ({
    ...state, ...payload,
  }),
});
