import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RequestUserForm from '../../components/RequestUserForm';
import Icon from '../../components/Icon';
import Container from '../../components/Container';
import Header from '../../components/Header';
import RequestInstitutionMutation from './request-institution-mutation';

class RequestInstitutionPage extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
    node: PropTypes.object,
    mutateRequestInstitution: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    requesting: false,
    userExists: false,
    isError: false,
    isDone: false,
  };

  getLinkBack() {
    return get(this.props, 'location.state.linkBack', null);
  }

  navigateToCaller() {
    const path = this.getLinkBack() || '/';
    this.props.history.push(path);
  }

  _handleCancel = () => {
    this.navigateToCaller();
  };

  /* istanbul ignore next */
  _handleDone = () => {
    const path = process.env.HE_LOGIN_URL;
    window.location = path;
  };

  _handleSave = ({
    firstName,
    lastName,
    email,
    institutionName,
    institutionWebsite,
    jobTitle,
    authorizedToPostPublicInformation,
    schedulesVisits,
    isCitizenOfEU,
    hasAgreedToTermsOfUse,
    hasAgreedToPrivacyPolicy,
  }) => {
    const { mutateRequestInstitution } = this.props;
    const onFailure = (error) => {
      const newState = { requesting: false, isError: true };
      if (error && error.message && error.message.includes('already exists.')) {
        newState.userExists = true;
      }
      this.setState(newState);
    };

    const onSuccess = () => {
      this.setState({ isDone: true });
    };

    this.setState({ requesting: true, isError: false, userExists: false });

    mutateRequestInstitution({
      variables: {
        input: {
          requestInstitution: {
            firstName,
            lastName,
            email,
            institutionName,
            institutionWebsite,
            jobTitle,
            authorizedToPostPublicInformation,
            schedulesVisits,
            isCitizenOfEU,
            hasAgreedToTermsOfUse,
            hasAgreedToPrivacyPolicy,
          },
        },
      },
    }).then(onSuccess).catch(onFailure);
  };

  render() {
    const { requesting, isError, isDone, userExists } = this.state;
    const linkBack = this.getLinkBack();
    return (
      <div>
        {
          linkBack &&
        (<Link to={linkBack}><Icon name="angle left" />Back</Link>)
        }
        <Container text className="requestHEUserPanel">
          <Header as="h1">
            <FormattedMessage
              id="he-userRequest.requestInstitutionHeader"
              description="Header for requesting to be the primary user of an new institution"
              defaultMessage="Request New Institution"
            />
          </Header>
          <RequestUserForm
            requesting={requesting}
            onSave={this._handleSave}
            onCancel={this._handleCancel}
            isError={isError}
            isDone={isDone}
            userExists={userExists}
            newInstitution
            onModalClose={this._handleDone}
            showJobTitle
            showAuthorizedToPost
            showSchedulesVisits
          />
        </Container>
      </div>
    );
  }
}

const requestInstitutionMutation = graphql(RequestInstitutionMutation, {
  name: 'mutateRequestInstitution',
});

export const RequestInstitutionPageComponent = withRouter(RequestInstitutionPage);

export default requestInstitutionMutation(RequestInstitutionPageComponent);
