import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import Grid from '../Grid';
import Button from '../Button';
import Icon from '../Icon';
import './styles.css';

const Header = ({ loginVisibility }) => {
  const sourceCookie = Cookie.get('x-source');
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={4} mobile={8} tablet={4}>
          <img
            src="https://static.intersect.hobsons.com/svg/intersect-by-powerschool.svg"
            width="100%"
            height="100%"
            alt="Intersect Logo"
          />
        </Grid.Column>
        <Grid.Column computer={12} mobile={8} tablet={12} textAlign="right">
          {loginVisibility !== false && (
            <Route
              path="/he"
              render={(props) => (
                <div>
                  <FormattedMessage id="he-registration.alreadyHaveAnAcount" defaultMessage="Already have an account?" />
                  <Button as="a" href={process.env.HE_LOGIN_URL} data-cy="he-sign-in-button" primary styleName="signIn">
                    <FormattedMessage id="he-registration.signin" defaultMessage="Sign In" />
                  </Button>
                </div>
              )}
            />
          )}
          {loginVisibility !== false && (
            <Route
              path="/hs"
              render={(props) => (
                <div>
                  <FormattedMessage id="hs-registration.alreadyHaveAnAcount" defaultMessage="Already have an account?" />
                  <Button as="a" href={process.env.HS_LOGIN_URL} data-cy="hs-sign-in-button" primary styleName="signIn">
                    <FormattedMessage id="hs-registration.signin" defaultMessage="Sign In" />
                  </Button>
                </div>
              )}
            />
          )}
          {sourceCookie && (
            <div id="x-source-branch">
              <Icon name="code branch" circular inverted color="pink" size="small" /> {sourceCookie}
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Header.propTypes = {
  loginVisibility: PropTypes.bool,
};

const mapStateToProps = (state) => ({ loginVisibility: state.loginButton.visible });

export default connect(mapStateToProps, {}, null, { pure: false })(Header);
