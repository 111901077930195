// import fragmentTypes from '@purple/match-graphql-schema/lib/schema/he-reg/fragmentTypes.json';
import { ApolloClient } from '@apollo/client';
import 'isomorphic-fetch';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { relayStylePagination } from '@apollo/client/utilities';

const httpLink = new BatchHttpLink({ uri: process.env.API_URL });

export default function getApolloClient() {
  let localLink;
  /* istanbul ignore else */
  if (process.env.MOCK_GRAPHQL || process.env.NODE_ENV === 'test') {
    localLink = require('./createLocalLink').default(); // eslint-disable-line global-require
  }

  const client = new ApolloClient({
    assumeImmutableResults: true,
    link: localLink || /* istanbul ignore next */ httpLink,
    cache: new InMemoryCache({
      typePolicies: {
        Viewer: {
          fields: {
            query: relayStylePagination(['filter']),
            queryHighSchools: relayStylePagination(['filter']),
            heAccounts: relayStylePagination(),
            hsAccounts: relayStylePagination(),
          },
        },
      },
    }),
  });
  return client;
}
