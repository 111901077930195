import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '../../components/Icon';
import Grid from '../../components/Grid';
import Header from '../../components/Header';
import Container from '../../components/Container';
import Segment from '../../components/Segment';
import RequestUserLink from '../../components/RequestUserLink';
import { setLoginVisibility } from '../../components/SiteHeader/actions';

const messages = defineMessages({
  requestLinkInstructionsMessage: {
    id: 'hs-userRequest.link.RequestUserInstructions',
    defaultMessage: 'To request an account for your institution,',
  },
  requestLink: {
    id: 'hs-userRequest.link.RequestUser',
    defaultMessage: 'please complete this form.',
  },
});

class InstitutionPage extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.shape({
        linkBack: PropTypes.object,
      }),
    }),
    setLoginVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    if (props.node) {
      props.setLoginVisibility(!props.node.isNavianceSchool);
    }
  }

  componentWillUnmount(props) {
    this.props.setLoginVisibility(null);
  }

  render() {
    const { node, location } = this.props;
    const linkBackToThis = pick(location, 'pathname', 'search');
    const linkBackToParent = get(location, 'state.linkBack');
    return (
      <div>
        {
          linkBackToParent &&
          (
            <Link to={linkBackToParent}><Icon name="angle left" />
              <FormattedMessage id="institution.backtosearch" defaultMessage="Back to search" />
            </Link>
          )
        }
        {!node && (
        <p>
          <FormattedMessage id="institution.notFound" defaultMessage="No Institution Found" />
        </p>
        )}
        {node && (
        <Container text>
          <Header as="h1">
            {node.name}
          </Header>
          <Segment>
            <Grid columns="equal" stackable>
              <Grid.Row centered>
                <Grid.Column as="section">
                  <div className="vcard">
                    <div className="adr">
                      <div className="street-address">{node.address.field_hs_elna_address_line1}</div>
                      <span className="locality">{node.address.field_hs_elna_city}</span>,&nbsp;
                      <span className="region">{node.address.field_hs_elna_state_latest}</span>&nbsp;
                      <span className="postal-code">{node.address.field_hs_eln_zip}</span>
                    </div>
                  </div>
                  <p />
                  {node.isNavianceSchool && (
                  <p>
                    <FormattedMessage
                      id="institution.logInViaNaviance"
                      defaultMessage="Please access the Counselor Community via {navianceLink}."
                      values={{ navianceLink: <a data-cy="naviance-login-link" href={process.env.NAVIANCE_LOGIN_URL}>Naviance</a> }}
                    />
                  </p>
                  )}
                  {!node.isNavianceSchool && (
                  <div>
                    {get(node, 'hsAccount.primaryUser') && (
                    <p>
                      <FormattedMessage id="institution.primaryUser.found" defaultMessage="Primary User -" />&nbsp;
                      {node.hsAccount.primaryUser.firstName} {node.hsAccount.primaryUser.lastName}
                    </p>
                    )}
                    {!get(node, 'hsAccount.primaryUser') && (
                    <p>
                      <FormattedMessage id="institution.primaryUser.notFound" defaultMessage="No Primary User Exists" />
                    </p>
                    )}
                    <RequestUserLink
                      linkBack={linkBackToThis}
                      parentLinkBack={linkBackToParent}
                      id={node.id}
                      linkInstructionsMessage={messages.requestLinkInstructionsMessage}
                      linkMessage={messages.requestLink}
                      userType="hs"
                    />
                  </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        )}
      </div>
    );
  }
}

export const InstitutionPageComponent = InstitutionPage;

export default connect(null, { setLoginVisibility })(InstitutionPage);
