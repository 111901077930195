import React from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';
import { FormattedMessage } from 'react-intl';
import omit from 'lodash/omit';
import Button from '../Button';
import Input from '../Input';
import Loader from '../Loader';
import Dimmer from '../Dimmer';
import Checkbox from '../Checkbox';
import Segment from '../Segment';
import Form from '../Form';
import Label from '../Label';
import Message from '../Message';
import Modal from '../Modal';
import Icon from '../Icon';
import Radio from '../Radio';
import TermsPrivacyLink from '../TermsPrivacyLink';
import './styles.css';

const missingValueLabel = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.missingValue" defaultMessage="Please enter a value" />
  </Label>
);
const missingEUMessage = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.missingEU" defaultMessage="Please select a value to proceed" />
  </Label>
);
const missingCheckboxMessage = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.missingCheckbox" defaultMessage="Please click the checkbox if you agree" />
  </Label>
);
const emailsNoMatchLabel = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.emailsNoMatch" defaultMessage="Email must match" />
  </Label>
);
const invalidLabel = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.provideValidEmail" defaultMessage="Please provide a valid email address." />
  </Label>
);
const verifyLabel = (
  <Label role="tooltip" basic color="red" pointing>
    <FormattedMessage id="common.label.verify" defaultMessage="Please verify that you are not a robot." />
  </Label>
);
const completeFieldsMessage = (
  <Message role="alert" negative>
    <Message.Header>
      <FormattedMessage id="common.label.completeFields" defaultMessage="Please complete all required fields." />
    </Message.Header>
  </Message>
);

export const elementIds = {
  firstNameId: 'request-he-user-first-name',
  lastNameId: 'request-he-user-last-name',
  emailId: 'request-he-user-email',
  verifyEmailId: 'request-he-user-verify-email',
  institutionNameId: 'request-institution-name',
  institutionWebsiteId: 'request-institution-website',
  jobTitleId: 'request-he-user-job-title',
  authorizedToPostPublicInformationId: 'request-he-user-authorized-to-post',
  schedulesVisitsId: 'request-he-user-schedule-visits',
  euCitizenId: 'request-he-user-isEuCitizen',
  termsOfUse: 'request-he-user-agree-termsofuse',
  privacy: 'request-he-user-agree-privacy',
};

function validateEmail(formData) {
  return formData.email.search('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$') === -1;
}

class RequestUserForm extends React.Component {
  static propTypes = {
    requesting: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    userExists: PropTypes.bool.isRequired,
    newInstitution: PropTypes.bool,
    institutionName: PropTypes.string,
    showJobTitle: PropTypes.bool,
    showAuthorizedToPost: PropTypes.bool,
    showSchedulesVisits: PropTypes.bool,
  };

  static defaultProps = {
    institutionName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      verifyEmail: '',
      institutionName: props.institutionName,
      jobTitle: '',
      authorizedToPostPublicInformation: false,
      schedulesVisits: false,
      verified: false,
      euOption: '',
      isCitizenOfEU: false,
      hasAgreedToTermsOfUse: false,
      hasAgreedToPrivacyPolicy: false,
      errors: {},
    };
  }

  _onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  _closeModal = () => {
    this.props.onModalClose();
  };

  _onChange = (e, { value, name } = {}) => {
    value = value || e.target.value;
    name = name || e.target.name;
    this.setState({ [name]: value });
  };

  _onRadioChange = (e, { name, value } = {}) => {
    name = name || e.target.name;
    value = value !== undefined ? value : e.target.value;
    this.setState({ [name]: value });
    // Below code is a hack to convert number to boolean and store in state for EU citizen value
    // We have to take this approach in order to display without default option for radio
    const stateName = 'isCitizenOfEU';
    this.setState({ [stateName]: Boolean(Number(value)) });
  };

  _onSubmit = (e) => {
    let formData = omit(this.state, 'errors', 'verified');
    const errors = this.validateForm(formData);
    this.setState({ errors });
    if (!Object.keys(errors).some((key) => errors[key])) {
      formData = omit(formData, 'euOption');
      this.props.onSave(formData);
    }
  };

  _onCancel = () => {
    this.props.onCancel();
  };

  validateForm = (formData) => (
    {
      missingFirstName: !formData.firstName,
      missingLastName: !formData.lastName,
      missingEmail: !formData.email,
      missingValidateEmail: !formData.verifyEmail,
      mismatchedEmail: formData.email !== formData.verifyEmail,
      invalidEmail: validateEmail(formData),
      missingInstitutionName: !formData.institutionName,
      missingInstitutionWebsite: !!this.props.newInstitution && !formData.institutionWebsite,
      missingJobTitle: !formData.jobTitle && this.props.showJobTitle,
      missingVerified: !this.state.verified,
      incomplete: this._verifyCompletion(formData),
      missingPrivacy: !formData.hasAgreedToPrivacyPolicy,
      missingTermsOfUse: !formData.hasAgreedToTermsOfUse,
      missingEUChoice: !formData.euOption,
    }
  );

  _verifyCompletion = (formData) => (
    !formData.firstName || !formData.lastName || !formData.email || !formData.verifyEmail ||
      !this.state.verified || (!this.state.jobTitle && this.props.showJobTitle) ||
      (!formData.hasAgreedToPrivacyPolicy) || (!formData.hasAgreedToTermsOfUse) || (!formData.euOption));

  _verifyCaptcha = () => {
    this.setState((prevState) => ({ verified: !prevState.verified }));
  };

  /* eslint-disable max-len */
  render() {
    const { showJobTitle, showAuthorizedToPost, showSchedulesVisits } = this.props;

    const { errors, firstName, lastName, email, verifyEmail, institutionName, institutionWebsite, jobTitle,
      authorizedToPostPublicInformation, schedulesVisits, hasAgreedToPrivacyPolicy, hasAgreedToTermsOfUse, euOption } = this.state;
    const { isError, isDone, userExists, newInstitution, requesting } = this.props;

    const missingFirstNameLabel = errors.missingFirstName ? missingValueLabel : null;
    const missingLastNameLabel = errors.missingLastName ? missingValueLabel : null;
    const missingEmailLabel = errors.missingEmail ? missingValueLabel : null;
    const missingValidateEmailLabel = errors.missingValidateEmail ? missingValueLabel : null;
    const mismatchedEmailLabel = errors.mismatchedEmail ? emailsNoMatchLabel : null;
    const invalidEmailLabel = errors.invalidEmail ? invalidLabel : null;
    const missingInstitutionNameLabel = errors.missingInstitutionName ? missingValueLabel : null;
    const missingInstitutionWebsiteLabel = errors.missingInstitutionWebsite ? missingValueLabel : null;
    const missingJobTitleLabel = errors.missingJobTitle ? missingValueLabel : null;
    const mustVerifyLabel = errors.missingVerified ? verifyLabel : null;
    const mustCompleteFieldsMessage = errors.incomplete ? completeFieldsMessage : null;
    const missingPrivacyLabel = errors.missingPrivacy ? missingCheckboxMessage : null;
    const missingTermsOfUseLabel = errors.missingTermsOfUse ? missingCheckboxMessage : null;
    const missingEULabel = errors.missingEUChoice ? missingEUMessage : null;

    const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY;

    const errorDetails = isError ? (
      <Message role="alert" negative>
        <Message.Header><FormattedMessage id="he-user.request.error" defaultMessage="Unable to submit request" /></Message.Header>
      </Message>
    ) : '';

    const termsOfUseDetails = (
      <label htmlFor={elementIds.termsOfUse} styleName="checkboxLabel">
        I have read and agree to the Intersect
        <TermsPrivacyLink
          link="https://static.intersect.hobsons.com/terms.html"
          title="Terms of Use"
        />
        <span> and</span>
        <TermsPrivacyLink
          link="https://static.intersect.hobsons.com/community.html"
          title="Community Guidelines"
        />
        , which serve as our contractual agreement.
      </label>
    );

    const privacyDetails = (
      <label htmlFor={elementIds.privacy} styleName="checkboxLabel">
        I have read and agree to the Intersect
        <TermsPrivacyLink
          link="https://www.powerschool.com/privacy/"
          title="Privacy Statement"
        />.
      </label>
    );

    const publicInformationLabel = (
      <label htmlFor={elementIds.authorizedToPostPublicInformationId} styleName="checkboxLabel">
        <FormattedMessage
          id="he-userRequest.authorizedToPostPublicInformation"
          defaultMessage="I'm authorized to post public information about my institution."
        />
      </label>
    );

    const scheduleVisitsLabel = (
      <label htmlFor={elementIds.schedulesVisitsId} styleName="checkboxLabel">
        <FormattedMessage
          id="he-userRequest.schedulesVisits"
          defaultMessage="I schedule visits to high schools."
        />
      </label>
    );
    return (
      <div data-cy="request-user-form-request-text">
        <FormattedMessage
          id="he-userRequest.requestText"
          defaultMessage="Answer all fields below to complete your request. You can expect a response from PowerSchool within 1 business day."
        />
        {errorDetails}
        <Dimmer.Dimmable as={Form} id="form-request-he-user" onSubmit={this._onSubmit} size="large">
          <Dimmer
            tabIndex={-1}
            role="progressbar"
            aria-describedby="Requesting User Account..."
            aria-busy={requesting}
            inverted
            active={requesting}
          >
            <Loader active={requesting} />
          </Dimmer>
          <Segment>
            {mustCompleteFieldsMessage}
            <Form.Field>
              <label htmlFor={elementIds.firstNameId}>
                <FormattedMessage id="he-userRequest.firstName" defaultMessage="First Name" />
              </label>
              <Input type="text" name="firstName" value={firstName} onChange={this._onChange}>
                <input id={elementIds.firstNameId} aria-required aria-invalid={!!missingFirstNameLabel} />
              </Input>
              {missingFirstNameLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.lastNameId}>
                <FormattedMessage id="he-userRequest.lastName" defaultMessage="Last Name" />
              </label>
              <Input type="text" name="lastName" value={lastName} onChange={this._onChange}>
                <input id={elementIds.lastNameId} aria-required aria-invalid={!!missingLastNameLabel} />
              </Input>
              {missingLastNameLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.emailId}>
                <FormattedMessage id="he-userRequest.email" defaultMessage="Email" />
              </label>
              <Input type="email" name="email" value={email} onChange={this._onChange}>
                <input
                  id={elementIds.emailId}
                  aria-required
                  aria-invalid={!!missingEmailLabel || !!mismatchedEmailLabel || !!invalidEmailLabel}
                />
              </Input>
              {missingEmailLabel || mismatchedEmailLabel || invalidEmailLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.verifyEmailId}>
                <FormattedMessage id="he-userRequest.verifyEmail" defaultMessage="Verify Email" />
              </label>
              <Input type="email" name="verifyEmail" value={verifyEmail} onChange={this._onChange}>
                <input
                  id={elementIds.verifyEmailId}
                  aria-required
                  aria-invalid={!!missingValidateEmailLabel || !!mismatchedEmailLabel || !!invalidEmailLabel}
                />
              </Input>
              {missingValidateEmailLabel || mismatchedEmailLabel || invalidEmailLabel}
            </Form.Field>
            <Form.Field>
              <label htmlFor={elementIds.institutionNameId}>
                <FormattedMessage id="he-userRequest.institutionName" defaultMessage="Institution Name" />
              </label>
              <Input
                type="text"
                name="institutionName"
                value={institutionName}
                disabled={!newInstitution}
                onChange={this._onChange}
              >
                <input id={elementIds.institutionNameId} aria-required aria-invalid={!!missingInstitutionNameLabel} />
              </Input>
              {missingInstitutionNameLabel}
            </Form.Field>
            {newInstitution && (
            <Form.Field>
              <label htmlFor={elementIds.institutionWebsiteId}>
                <FormattedMessage id="he-userRequest.institutionWebsite" defaultMessage="Website" />
              </label>
              <Input type="text" name="institutionWebsite" value={institutionWebsite || ''} onChange={this._onChange}>
                <input id={elementIds.institutionWebsiteId} aria-required aria-invalid={!!missingInstitutionWebsiteLabel} />
              </Input>
              {missingInstitutionWebsiteLabel}
            </Form.Field>
            )}
            {showJobTitle && (
            <Form.Field>
              <label htmlFor={elementIds.jobTitleId}>
                <FormattedMessage id="he-userRequest.jobTitle" defaultMessage="Job Title" />
              </label>
              <Input type="text" name="jobTitle" value={jobTitle} onChange={this._onChange}>
                <input id={elementIds.jobTitleId} aria-required aria-invalid={!!missingJobTitleLabel} />
              </Input>
              {missingJobTitleLabel}
            </Form.Field>
            )}
            {showAuthorizedToPost && (
            <Form.Field>
              <Checkbox
                id={elementIds.authorizedToPostPublicInformationId}
                name="authorizedToPostPublicInformation"
                onChange={this._onCheckboxChange}
                value={String(authorizedToPostPublicInformation || false)}
                label={publicInformationLabel}
              />
            </Form.Field>
            )}
            {showSchedulesVisits && (
            <Form.Field>
              <Checkbox
                id={elementIds.schedulesVisitsId}
                name="schedulesVisits"
                onChange={this._onCheckboxChange}
                value={String(schedulesVisits || false)}
                label={scheduleVisitsLabel}
              />
            </Form.Field>
            )}
            <Form.Field>
              <div id={elementIds.euCitizenId} aria-required aria-invalid={!!missingEULabel}>
                <legend styleName="legend">
                  Are you an EU citizen?
                </legend>
                <div styleName="radioGroup">
                  <Radio
                    id="yesradio"
                    name="euOption"
                    label="Yes"
                    styleName="radioButton"
                    value="1"
                    checked={euOption === '1'}
                    onChange={this._onRadioChange}
                  />
                  <Radio
                    id="noradio"
                    styleName="radioButton"
                    type="radio"
                    name="euOption"
                    label="No"
                    value="0"
                    checked={euOption === '0'}
                    onChange={this._onRadioChange}
                  />
                </div>
              </div>
              {missingEULabel}
            </Form.Field>
            <Form.Field data-cy="terms-of-use-field">
              <Checkbox
                id={elementIds.termsOfUse}
                name="hasAgreedToTermsOfUse"
                aria-required
                aria-invalid={!!missingTermsOfUseLabel}
                onChange={this._onCheckboxChange}
                value={String(hasAgreedToTermsOfUse || false)}
                label={termsOfUseDetails}
              />
              {missingTermsOfUseLabel}
            </Form.Field>
            <Form.Field data-cy="privacy-policy-field">
              <Checkbox
                id={elementIds.privacy}
                name="hasAgreedToPrivacyPolicy"
                aria-required
                aria-invalid={!!missingPrivacyLabel}
                onChange={this._onCheckboxChange}
                value={String(hasAgreedToPrivacyPolicy || false)}
                label={privacyDetails}
                styleName="privacy"
              />
              {missingPrivacyLabel}
            </Form.Field>
            <Form.Field>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                verifyCallback={this._verifyCaptcha}
                expiredCallback={this._verifyCaptcha}
                render="onload"
              />
              {mustVerifyLabel}
            </Form.Field>
            <Button primary type="button" data-cy="cancel-button" onClick={this._onCancel}>
              <FormattedMessage id="common.button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button primary type="submit" data-cy="request-user">
              <FormattedMessage id="common.button.requestUser" defaultMessage="Request User" />
            </Button>
            <div styleName="consentText">
              <p>By submitting this form, you acknowledge that an account will be created and maintained for you on Intersect.
                The account includes periodic communications from Intersect regarding product-related updates, services and surveys.
              </p>
              <p>You have the option to withdraw your consent at any time by contacting us at
                <a styleName="consentEmail" href="mailto:counselorcommunity@eab.com"> counselorcommunity@eab.com </a>
                and we will remove your account and delete your personal information.
              </p>
            </div>
          </Segment>
        </Dimmer.Dimmable>
        <Modal size="small" open={isDone} onClose={this._closeModal} closeOnDimmerClick={false}>
          <Modal.Header>
            <FormattedMessage
              id="he-user.request.success-header"
              defaultMessage="Your request has been submitted."
            />
          </Modal.Header>
          <Modal.Content>
            <p>
              <FormattedMessage
                id="he-user.request.success-message"
                defaultMessage="Your request has been successfully submitted. You can expect a response from PowerSchool within 1 business day."
              />
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive icon="checkmark" labelPosition="right" content="OK" onClick={this._closeModal} />
          </Modal.Actions>
        </Modal>
        <Modal size="small" open={userExists} onClose={this._closeModal} closeOnDimmerClick={false}>
          <Modal.Header>
            <Icon name="mail" />
            <FormattedMessage id="he-user.create.email-exists-header" defaultMessage="Account already exists" />
          </Modal.Header>
          <Modal.Content>
            <p>
              <FormattedMessage
                id="he-user.create.email-exists-message"
                defaultMessage="Please use the forgot password link on the login page."
              />
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive icon="checkmark" labelPosition="right" content="OK" onClick={this._closeModal} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default RequestUserForm;
