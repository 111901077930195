/*
 *
 * LanguageProvider reducer
 *
 */

import { CHANGE_LOCALE } from './constants';
import { createReducer } from '../../utils';

const initialState = {
  locale: navigator.language,
};

export default createReducer(initialState, {
  [CHANGE_LOCALE]: (state, payload) => ({
    ...state, ...payload,
  }),
});
