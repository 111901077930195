/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import get from 'lodash/get';

const LanguageProvider = ({ locale, children, messages }) => {
  const localeMessages = messages[locale] || messages[locale.split('-')[0]];
  return (
    <IntlProvider key={locale} locale={locale} messages={localeMessages}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = (state) => ({ locale: get(state, 'language.locale') });

export default connect(mapStateToProps)(LanguageProvider);
