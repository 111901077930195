import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { SimpleLoadingComponent } from '../../utils/apollo';

import InstitutionPage from './institution-page';

const query = gql`
  query CollegeCore($scid: String!) {
    collegeByScid(id: $scid) {
      id
      name
      heAccount {
        id
        primaryUser {
          firstName
          lastName
          name
        }
      }
      address {
        street
        street2
        city
        state
        postalCode
      }
    }
  }`;

const graphqlData = graphql(query, {
  options: ({ match }) => ({
    errorPolicy: 'all',
    variables: {
      scid: match.params.scid,
    },
  }),
});

export default withRouter(SimpleLoadingComponent(
  graphqlData,
)(InstitutionPage));
